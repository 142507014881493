<template>
  <main
    class="flex flex-col justify-center text-center w-full md:w-1/2 mx-auto pt-10 pb-24"
  >
  <SchemaOrg 
      type="restaurantList" 
      :data="{ restaurants: cityPlaces }" 
    />
    <header>
      <h1 class="text-lg/10">Muala Food {{ cityName }}</h1>
      <meta
        name="description"
        :content="`Najlepsze restauracje w ${cityName} - przewodnik kulinarny Muala Food. Sprawdź opinie, menu i rekomendacje.`"
      />
    </header>

    <!-- Nawigacja alfabetyczna na mobile -->
    <nav
      class="md:hidden overflow-x-auto py-4"
      aria-label="Alfabetyczna nawigacja mobilna"
    >
      <div class="flex whitespace-nowrap px-2">
        <button
          v-for="letter in alphabet"
          :key="letter"
          class="px-3 py-2 text-sm rounded-full mx-1"
          :class="{
            'bg-orange-500 text-white': hasPlacesForLetter(letter),
            'bg-gray-100 text-gray-400': !hasPlacesForLetter(letter),
          }"
          @click="scrollToLetter(letter)"
          :aria-label="`Przewiń do sekcji ${letter}`"
        >
          {{ letter }}
        </button>
      </div>
    </nav>

    <div class="p-5">
      <!-- Lista restauracji -->
      <section class="restaurants-list" aria-label="Lista restauracji">
        <ul
          class="text-gray-600 text-left p-5 grid grid-cols-1 md:grid-cols-2 gap-4 mt-10"
          role="list"
        >
          <template v-for="letter in alphabet" :key="letter">
            <template v-if="getPlacesByLetter(letter).length > 0">
              <li
                :id="`section-${letter}`"
                class="col-span-full text-xl font-bold pt-4 letter flex items-left -ml-0"
                role="separator"
                :aria-label="`Sekcja ${letter}`"
              >
                <span
                  class="w-10 h-10 bg-orange-500 text-white rounded-full flex items-center justify-center -ml-5"
                >
                  {{ letter }}
                </span>
              </li>
              <li
                v-for="place in getPlacesByLetter(letter)"
                :key="place.id"
                :class="{
                  'restaurant-item': true,
                  muala: place.isMuala,
                }"
                role="article"
              >
                <h2 class="text-black text-xl font-bold truncate">
                  <a
                    :id="place.id"
                    :href="`https://muala.app/${place.city.toLowerCase()}/${place.title
                      .toLowerCase()
                      .replace(' - ', ' ')
                      .replace(/ /g, '-')}.html`"
                    :title="`Zobacz szczegółową opinię o restauracji ${place.title}`"
                    class=""
                  >
                    {{ place.title }}</a
                  >
                </h2>
                <div
                  class="mt-0 text-sm"
                  role="list"
                  aria-label="Adres restauracji"
                >
                  <a
                    v-if="place.cid"
                    :id="`maps-${place.id}`"
                    :href="`https://maps.google.com/?cid=${place.cid}`"
                    target="_blank"
                    rel="noopener"
                    :title="`Przejdź do ${place.street} w Google Maps`"
                    class=""
                  >
                    {{ place.street }}
                  </a>
                </div>
                <div
                  class="types flex flex-wrap gap-2 mt-2"
                  role="list"
                  aria-label="Rodzaje kuchni"
                >
                  <span
                    v-for="type in place.type"
                    :key="type"
                    :class="[
                      'text-sm rounded-full py-1 px-4 mx-1 -ml-1 inline-block mix-blend-multiply',
                      type === 'MUALA'
                        ? 'bg-orange-500 text-white'
                        : 'bg-gray-100 text-slate-600',
                    ]"
                    role="listitem"
                  >
                    {{ type }}
                  </span>
                </div>

                <div class="links flex flex-wrap gap-2 w-full">
                  <a
                    v-if="place.cid"
                    :id="`maps-${place.id}`"
                    :href="`https://maps.google.com/?cid=${place.cid}`"
                    target="_blank"
                    rel="noopener"
                    :title="`Sprawdź lokalizację ${place.title} w Google Maps`"
                    class="border-orange-500 border-2 bg-orange-500 font-bold text-sm text-white whitespace-nowrap rounded-full py-2 px-4 cursor-pointer hover:bg-orange-700 hover:border-orange-700 hover:text-white flex-1 basis-full sm:basis-auto text-center"
                  >
                    przejdź do Google Maps
                  </a>

                  <a
                    v-if="place.url_pyszne"
                    :id="`maps-${place.url_pyszne}`"
                    :href="generateUrl(place.url_pyszne)"
                    target="_blank"
                    rel="noopener"
                    :title="`Sprawdź lokalizację ${place.title} w Google Maps`"
                    class="border-green-600 border-2 bg-green-600 font-bold text-sm text-white whitespace-nowrap rounded-full py-2 px-4 cursor-pointer hover:bg-green-700 hover:border-green-700 hover:text-white flex-1 basis-full sm:basis-auto text-center"
                  >
                    zamów z dostawą
                  </a>

                  <!-- <a
                    :id="place.id"
                    :href="`https://muala.app/${place.city.toLowerCase()}/${place.title.toLowerCase().replace(/ /g, '-')}.html`"
                    :title="`Zobacz szczegółową opinię o restauracji ${place.title}`"
                    class="review-link"
                  >
                    zobacz opinię
                  </a> -->
                </div>
              </li>
            </template>
          </template>
        </ul>
      </section>
    </div>

    <!-- Nawigacja alfabetyczna desktop -->
    <nav
      class="fixed right-4 top-40 hidden md:block border-2 border-gray-200 rounded-full py-2"
      aria-label="Alfabetyczna nawigacja desktop"
    >
      <div
        class="bg-white/80 backdrop-blur-sm py-2 px-1 rounded-lg overflow-y-auto scrollbar-hide"
      >
        <div class="flex flex-col">
          <button
            v-for="letter in alphabet"
            :key="letter"
            class="text-xs w-6 h-6 flex items-center justify-center rounded-full hover:bg-orange-500 hover:text-white transition-all duration-200"
            :class="{
              'text-orange-500 font-bold': hasPlacesForLetter(letter),
              'text-gray-300': !hasPlacesForLetter(letter),
            }"
            @click="scrollToLetter(letter)"
            :aria-label="`Przewiń do sekcji ${letter}`"
          >
            {{ letter }}
          </button>
        </div>
      </div>
    </nav>

    <div class="fixed inset-x-0 bottom-0 flex justify-center pb-4">
      <button
        @click="goBack"
        class="rounded-full cursor-pointer bg-orange-500 p-2 w-12 h-12 text-white hover:text-opacity-55 transition duration-300 flex justify-center items-center"
        aria-label="Powrót"
      >
        <span class="material-icons-round">close</span>
      </button>
    </div>
  </main>
</template>

<script setup>
import { ref, onMounted, computed, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import getDocById from "../composables/getDocById.js";
import SchemaOrg from '@/components/SchemaOrg.vue';

const route = useRoute();
const router = useRouter();

const alphabet = "AĄBCĆDEĘFGHIJKLŁMNŃOÓPQRSŚTUVWXYZŹŻ".split("");
const cityPlaces = ref([]);
const cityName = ref("");

const getAllSavedToFirebase = async () => {
  const maxAttempts = 10;
  const retryDelay = 500;

  for (let attempt = 1; attempt <= maxAttempts; attempt++) {
    try {
      const doc = await getDocById("data", "1");

      if (!doc || !doc.all) {
        console.warn(`Attempt ${attempt}: Document not found.`);
        if (attempt < maxAttempts) {
          await new Promise((resolve) => setTimeout(resolve, retryDelay));
          continue;
        } else {
          throw new Error("Document does not exist after maximum attempts.");
        }
      }

      return Object.values(doc.all);
    } catch (error) {
      console.error(`Error on attempt ${attempt}:`, error);
      if (attempt === maxAttempts) {
        return [];
      }
      await new Promise((resolve) => setTimeout(resolve, retryDelay));
    }
  }
  return [];
};

onMounted(async () => {
  try {
    const fetchedData = await getAllSavedToFirebase();
    console.log(fetchedData);
    if (fetchedData && fetchedData.length > 0) {
      cityName.value =
        route.params.city.charAt(0).toUpperCase() + route.params.city.slice(1);

      cityPlaces.value = fetchedData
        .filter(
          (place) =>
            place.city.toLowerCase() === route.params.city.toLowerCase()
        )
        .sort((a, b) => a.title.localeCompare(b.title, "pl"));
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    cityPlaces.value = [];
  }
});

// Funkcja grupująca miejsca według pierwszej litery
const getPlacesByLetter = (letter) => {
  return cityPlaces.value.filter((place) =>
    place.title.toUpperCase().startsWith(letter)
  );
};

// Sprawdza czy są miejsca dla danej litery
const hasPlacesForLetter = (letter) => {
  return getPlacesByLetter(letter).length > 0;
};

// Funkcja scrollowania do sekcji
const scrollToLetter = (letter) => {
  if (hasPlacesForLetter(letter)) {
    const element = document.getElementById(`section-${letter}`);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }
};

const generateUrl = (url) => {
  if (url.includes("pyszne.pl")) {
    const resNameUrl = router.currentRoute.value.fullPath
      .split("/")
      .pop()
      .replace(".html", "");
    return `https://www.awin1.com/cread.php?awinmid=12109&awinaffid=1643147&clickref=${resNameUrl}&ued=${encodeURIComponent(
      url
    )}`;
  } else if (url.includes("roomservice.pl")) {
    return `${url}?muala.app`;
  } else {
    return url;
  }
};

watch(
  () => cityName.value,
  (newName) => {
    document.title = `${newName} restauracje - Muala Food`;
  }
);

const goBack = () => {
  router.push({ name: "lista" });
};
</script>

<style scoped>
h1 {
  font-size: 2.5rem;
  font-weight: 900;
  margin-bottom: 1rem;
}
ul {
  list-style-type: none;
  padding: 0;
  margin-left: 0rem;
  position: relative;
}

li {
  text-align: left;
  border: 2px solid rgb(228, 228, 228);
  border-radius: 17px;
  padding: 15px 20px;
  background-color: rgb(255, 255, 255);
  position: relative;
  display: flex;

  align-items: left;
  flex-direction: column; /* Stack child elements vertically */
}
.city li a {
  background-color: #f3f4f6;
  font-size: 0.875rem;
  color: black;
  border-radius: 99px;
  margin: 0rem;
  display: inline-block;
  padding: 0.5rem 1.3rem;
  text-decoration: none;
  background-color: rgb(255 153 58);
  position: absolute;
  right: 15px; /* Ustawienie elementu do prawej krawędzi kontenera */
  bottom: 15px;
}

.city li a:hover {
  text-decoration: none;
  background-color: rgb(255, 135, 23);
  transition-duration: 0.3s;
  cursor: pointer;
}

.letter-index {
  position: fixed;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  z-index: 50;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 0.5rem;
  border-radius: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.letter {
  border: none;
}

/* Dodaj margin-right do głównej listy na desktopie */
@media (min-width: 768px) {
  .restaurant-list {
    margin-right: 3rem;
  }
}

@media screen and (max-height: 800px) {
  .letter-navigation button {
    @apply text-[10px] w-5 h-5;
  }
}

.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.scrollbar-hide::-webkit-scrollbar {
  display: none; /* Chrome, Safari and Opera */
}

.muala {
  border: 2px solid rgb(255 153 58);
  background-color: rgb(255, 234, 215);
}

.muala .tag {
  color: white;
}

nav {
  padding: 15px 5px;
}

.links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-left: -3px;
}
</style>
