import { createApp } from 'vue';
import { createHead } from '@vueuse/head';
import App from './App.vue';
import router from './router';
import './registerServiceWorker';
import { GoogleMap } from 'vue3-google-map';
import '@/assets/css/tailwind.css';
import 'material-icons/iconfont/material-icons.css';
import mitt from 'mitt';

const emitter = mitt(); // Tworzenie instancji event busa
const head = createHead(); // Tworzenie instancji @vueuse/head

const app = createApp(App);

// ✅ Poprawne przekazanie emittera jako provide/inject
app.provide('$emitter', emitter);

app.component('GoogleMap', GoogleMap);
app.use(router);
app.use(head); // Używamy @vueuse/head

app.mount('#app');




//import { createApp } from 'vue';
// import { createHead } from '@vueuse/head';
// import App from './App.vue';
// import router from './router';
// import './registerServiceWorker';
// import { GoogleMap } from 'vue3-google-map';
// import '@/assets/css/tailwind.css';
// import 'material-icons/iconfont/material-icons.css';
// import mitt from 'mitt';

// const emitter = mitt();
// const head = createHead(); // Tworzymy instancję @vueuse/head

// const app = createApp(App);

// app.config.globalProperties.$emitter = emitter;

// app.component('GoogleMap', GoogleMap);
// app.use(router);
// app.use(head); // Używamy @vueuse/head

// app.mount('#app');


 