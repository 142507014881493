<template>
  <div
    class="flex flex-col justify-center text-center w-full md:w-1/2 mx-auto pt-10 pb-24"
  >
    <header>
      <meta
        name="description"
        content="`Najlepsze restauracje Muala Food - przewodnik kulinarny Muala Food. Sprawdź opinie, menu i rekomendacje.`"
      />

      <h1 class="text-lg/10">Muala Food Lista&nbsp;Miast</h1>
      <p>
        Wybierz miasto ktore Cię interesuje i znajdź food muala w Twojej
        okolicy.
      </p>
    </header>
    <!-- Poziomy alfabet na mobile -->
    <div class="md:hidden overflow-x-auto py-4">
      <div class="flex whitespace-nowrap px-2">
        <button
          v-for="letter in alphabet"
          :key="letter"
          class="px-3 py-2 text-sm rounded-full mx-1"
          :class="{
            'bg-orange-500 text-white': hasCitiesForLetter(letter),
            'bg-gray-100 text-gray-400': !hasCitiesForLetter(letter),
          }"
          @click="scrollToLetter(letter)"
        >
          {{ letter }}
        </button>
      </div>
    </div>

    <!-- Lista miast z sekcjami alfabetycznymi -->
    <section
      v-if="uniqueCities.length > 0"
      aria-label="Lista miast"
      class="p-5"
    >
      <h2 class="sr-only">Lista miast według alfabetu</h2>
      <ul
        class="text-gray-600 text-left p-5 grid grid-cols-1 md:grid-cols-3 gap-4 mt-10"
      >
        <template v-for="letter in alphabet" :key="letter">
          <template v-if="getCitiesByLetter(letter).length > 0">
            <li :id="`section-${letter}`" class="col-span-full">
              <h3 class="text-xl font-bold pt-4 letter flex items-left">
                <span
                  class="w-10 h-10 bg-orange-500 text-white rounded-full flex items-center justify-center ml-0"
                >
                  {{ letter }}
                </span>
              </h3>
            </li>
            <li
              v-for="city in getCitiesByLetter(letter)"
              :key="city"
              class="city text-black text-xl font-bold flex p-5"
            >
              <router-link
                :to="`/lista/${city.toLowerCase()}`"
                class="hover:text-orange-500 transition duration-300"
                :title="`Zobacz restauracje Muala Food w mieście ${city}`"
              >
                {{ city }}
              </router-link>
            </li>
          </template>
        </template>
      </ul>
    </section>

    <!-- Alfabet po prawej stronie na desktopie -->
    <div
      class="fixed right-4 top-40 hidden md:block border-2 border-gray-200 rounded-full py-2"
    >
      <div
        class="bg-white/80 backdrop-blur-sm py-2 px-1 rounded-lg overflow-y-auto scrollbar-hide"
      >
        <div class="flex flex-col">
          <button
            v-for="letter in alphabet"
            :key="letter"
            class="text-xs w-6 h-6 flex items-center justify-center rounded-full hover:bg-orange-500 hover:text-white transition-all duration-200"
            :class="{
              'text-orange-500 font-bold': hasCitiesForLetter(letter),
              'text-gray-300': !hasCitiesForLetter(letter),
            }"
            @click="scrollToLetter(letter)"
          >
            {{ letter }}
          </button>
        </div>
      </div>
    </div>

    <div class="fixed inset-x-0 bottom-0 flex justify-center pb-4">
      <button
        @click="goToHomePage()"
        class="rounded-full cursor-pointer bg-orange-500 p-2 w-12 h-12 text-white hover:text-opacity-55 transition duration-300 flex justify-center items-center"
        aria-label="Powrót do strony głównej"
      >
        <span class="material-icons-round" aria-hidden="true">close</span>
      </button>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import { ref, onBeforeMount, inject } from "vue";

import getDocById from "../composables/getDocById.js";

const router = useRouter();
const uniqueCities = ref([]);
const alphabet = "ABCĆDEFGHIJKLŁMNOPQRSŚTUVWYZŹŻ".split("");

const emitter = inject("$emitter");

const getAllSavedToFirebase = async () => {
  const maxAttempts = 10;
  const retryDelay = 500;

  for (let attempt = 1; attempt <= maxAttempts; attempt++) {
    try {
      const doc = await getDocById("data", "1");

      if (!doc || !doc.all) {
        console.warn(`Attempt ${attempt}: Document not found.`);
        if (attempt < maxAttempts) {
          await new Promise((resolve) => setTimeout(resolve, retryDelay));
          continue;
        } else {
          throw new Error("Document does not exist after maximum attempts.");
        }
      }

      return Object.values(doc.all);
    } catch (error) {
      console.error(`Error on attempt ${attempt}:`, error);
      if (attempt === maxAttempts) {
        return [];
      }
      await new Promise((resolve) => setTimeout(resolve, retryDelay));
    }
  }
  return [];
};

const getCitiesByLetter = (letter) => {
  return uniqueCities.value.filter((city) =>
    city.toUpperCase().startsWith(letter)
  );
};

const hasCitiesForLetter = (letter) => {
  return getCitiesByLetter(letter).length > 0;
};

const scrollToLetter = (letter) => {
  if (hasCitiesForLetter(letter)) {
    const element = document.getElementById(`section-${letter}`);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }
};

onBeforeMount(async () => {
  const fetchedData = await getAllSavedToFirebase();
  console.log(fetchedData);

  const removeCity = [
    "Bangkok",
    "Bari",
    "Berlin",
    "Budapest",
    "Florida City",
    "Ko Pha-ngan",
    "Key West",
    "Miami",
    "Tajlandia",
  ];

  uniqueCities.value = [...new Set(fetchedData.map((item) => item.city))]
    .filter((city) => {
      return !removeCity.includes(city);
    })
    .sort((a, b) => a.localeCompare(b, "pl"));

  // Emitowanie listy miast
  if (emitter) {
    emitter.emit("cities-loaded", uniqueCities.value);
  }
});

const goToHomePage = () => {
  router.push({ name: "map" });
};
</script>

<style scoped>
h1 {
  font-size: 2.5rem;
  font-weight: 900;
  margin-bottom: 1rem;
}
ul {
  list-style-type: none;
  padding: 0;
  margin-left: 0rem;
  position: relative;
}

li.city {
  text-align: left;
  border: 2px solid rgb(228, 228, 228);
  border-radius: 17px;
  padding: 15px 20px;
  background-color: rgb(255, 255, 255);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: left;
  flex-direction: column;
}

.letter {
  border: none;
}

.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

@media screen and (max-height: 800px) {
  .letter-navigation button {
    @apply text-[10px] w-5 h-5;
  }
}
</style>
