<template>
  <div class="z-50 fixed top-5 right-5 sm:right-2 sm:top-2 flex items-center justify-end">
    <button
      @click="showMenu"
      class="rounded-full text-4xl sm:text-3xl p-2 hover:animate-spin flex justify-center items-center ml-auto cursor-pointer"
      aria-label="Toggle menu"
    >
      <span v-if="isMenu">🧅</span>
      <span v-else>🍔</span>
    </button>
  </div>

  <div v-if="isMenu">
    <div id="menu" class="fixed top-0 left-0 z-40 overflow-auto flex flex-col justify-center items-center pt-0 sm:pt-20 pb-20 bg-white text-black font-bold text-lg w-full h-screen">
      <div class="flex flex-col gap-y-[35px] justify-center text-3xl">
        <router-link :to="{ name: 'opis' }" class="hover:text-orange-500 transition duration-300" @click="isMenu = false">
          o pomyśle
        </router-link>
        <a href="https://buycoffee.to/mr.tomato" target="blank" class="hover:text-orange-500 transition duration-300">
          wsparcie projektu
        </a>
        <a href="mailto:kebab@muala.app" class="hover:text-orange-500 transition duration-300">kontakt</a>
      </div>
      <div class="w-full z-20 text-xs text-orange-500 pt-2 text-center x-auto bg-white">
        <div>made with ❤️ × 🌯 a flavor blast in every byte!</div>
      </div>
    </div>

    <div class="fixed z-50 inset-x-0 bottom-0 flex justify-center pb-4">
      <div
        @click="showMenu"
        class="rounded-full cursor-pointer bg-orange-500 p-2 w-12 h-12 text-white hover:text-opacity-55 transition duration-300 flex justify-center items-center"
      >
        <span class="material-icons-round">close</span>
      </div>
    </div>
  </div>

  <router-view />
</template>

<script setup>
import { ref, computed, watch, inject, onUnmounted, nextTick } from "vue";
import { useHead } from "@vueuse/head";
import { useRoute } from "vue-router";
import { setupAnalytics } from '@/composables/analytics.js';

setupAnalytics();

const isMenu = ref(false);
const showMenu = () => {
  isMenu.value = !isMenu.value;
};

const route = useRoute();
const emitter = inject('$emitter');

if (!emitter) {
  console.warn("⚠️ $emitter is not provided. Make sure it's initialized in main.js");
}

const restaurantData = ref(null);


// 🔹 Odbieramy listę miast
const cities = ref([]);

const onCitiesLoaded = (data) => {
  cities.value = data;
};

if (emitter) {
  emitter.on("cities-loaded", onCitiesLoaded);
}


// Odbieranie danych restauracji z `MapView.vue`
const onRestaurantSelected = (data) => {
  restaurantData.value = data;
};

if (emitter) {
  emitter.on('restaurant-selected', onRestaurantSelected);
}

// Usuwanie nasłuchiwacza, aby uniknąć wycieków pamięci
onUnmounted(() => {
  if (emitter) {
    emitter.off('restaurant-selected', onRestaurantSelected);
    emitter.off('cities-loaded', onCitiesLoaded);
  }
});

// Funkcja zamiany na `Title Case`
const toTitleCase = (str) => {
  return str
    .split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

// Dynamiczne meta dane i JSON-LD
const metaData = computed(() => {
  const path = route.path;
  let title = "Mapa MUALA - Odkrywaj najlepsze lokale!";
  let description = "Mapa MUALA: Znajdź najlepsze kebaby, zapiekanki food i więcej. Odkrywaj smaki z Książulem i Wojkiem!";
  let image = "https://muala.app/img/muala.app.png";
  let url = `https://muala.app${path}`;

  let schema = null;

  if (path === "/") {
    // 🏠 Strona główna
    title = "Mapa MUALA - Odkrywaj najlepsze lokale!";
    description = "Mapa MUALA: Znajdź najlepsze kebaby, zapiekanki food i więcej. Odkrywaj smaki z Książulem i Wojkiem!";
    schema = {
      "@context": "https://schema.org",
      "@type": "WebSite",
      "name": "Mapa MUALA",
      "url": url,
      "description": description,
      "image": image
    };
  } else if (path === "/wsparcie") {
    // 💰 Podstrona wsparcia
    title = "Wsparcie projektu - Mapa MUALA";
    description = "Pomóż mi rozwijać MUALA FOOD i wesprzyj wirtualna kawa jesli uwazasz ze to pomocny serwis. Dzieki!";
    schema = {
      "@context": "https://schema.org",
      "@type": "WebPage",
      "name": title,
      "description": description,
      "url": url
    };
  } else if (path === "/o-pomysle") {
    // 📖 Podstrona o pomyśle
    title = "Mapa MUALA - Nieoficjalna Mapa Ksążula i Najlepszych Miejscówek na Jedzenie";
    description = "Siemano! To nieoficjalna mapa Ksążula – projekt tworzony z pasji do dobrego żarcia. Odkrywaj najlepsze miejscówki i sprawdź, co poleca Książulo. Smacznego!";
    schema = {
      "@context": "https://schema.org",
      "@type": "AboutPage",
      "name": title,
      "description": description,
      "url": url
    };
  } if (path === "/lista") {
    // 🌍 Strona listy miast
    title = "Lista miast - Mapa MUALA food";
    description = "Przeglądaj listę miast i odkrywaj najlepsze restauracje oznaczone MUALA.";

    schema = {
      "@context": "https://schema.org",
      "@graph": [
        {
          "@type": "WebPage",
          "name": title,
          "description": description,
          "url": url
        },
        {
          "@type": "ItemList",
          "name": "Lista miast w MUALA FOOD APP",
          "description": "Wybierz miasto i zobacz polecane restauracje.",
          "itemListElement": cities.value.map((miasto, index) => ({
            "@type": "ListItem",
            "position": index + 1,
            "item": {
              "@type": "City",
              "name": miasto,
              "url": `https://muala.app/lista/${miasto.toLowerCase().replace(/ /g, "-")}`
            }
          }))
        }
      ]
    };
  }

 else if (route.params.city && !route.params.restaurantName) {
    // 🏙️ Strona listy restauracji w danym mieście
    const city = toTitleCase(route.params.city);
    title = `${city} - najlepsze lokale Mapa MUALA FOOD APP. Smaczenego!`;
    description = `${city} - miejsca oznaczone "Muala" i te odwiedzone przez Książula | Muala Food App`;

    schema = {
      "@context": "https://schema.org",
      "@type": "CityPage",
      "name": title,
      "description": description,
      "url": url
    };
  } else if (route.params.city && route.params.restaurantName) {
    // 🍽️ Strona konkretnej restauracji
    const city = toTitleCase(route.params.city);
    const restaurant = toTitleCase(route.params.restaurantName.replace(".html", ""));

    title = `${restaurant} opinia - ${city} Mapa MUALA FOOD odkryj z Ksiązulem`;
    description = `${restaurant} ${city} - sprawdź opinie i dowiedz się, czy warto odwiedzić to miejsce.`;

    
    if (restaurantData.value) {
      schema = {
        "@context": "https://schema.org",
        "@type": "Restaurant",
        "@id": `https://muala.app/${restaurantData.value.adres.city.toLowerCase()}/${restaurantData.value.title.toLowerCase().replace(' - ', ' ').replace(/ /g, '-')}.html`,
        "name": restaurantData.value.title,
        "description": restaurantData.value.reviews?.[0]?.shortDesc || "Brak opisu",
        "address": {
          "@type": "PostalAddress",
          "addressCountry": "Poland",
          "addressLocality": restaurantData.value.adres?.city || "Nieznane miasto",
          "postalCode": restaurantData.value.adres?.postcode || "00000",
          "streetAddress": restaurantData.value.adres?.street || "Nieznana ulica"
        },
        "geo": {
          "@type": "GeoCoordinates",
          "latitude": restaurantData.value.position?.lat || 0,
          "longitude": restaurantData.value.position?.lng || 0
        },
        "url": `https://muala.app/${restaurantData.value.adres.city.toLowerCase()}/${restaurantData.value.title.toLowerCase().replace(' - ', ' ').replace(/ /g, '-')}.html`,
        "hasMap": restaurantData.value.cid ? `https://maps.google.com/?cid=${restaurantData.value.cid}` : undefined,
        "servesCuisine": restaurantData.value.type?.join(", ") || "Nieznana kuchnia",
        "datePublished": restaurantData.value.publishedAt || "Nieznana data",
        "dateModified": restaurantData.value.editDate || restaurantData.value.publishedAt || "Nieznana data",
        "offers": restaurantData.value.url_pyszne ? {
          "@type": "Offer",
          "url": restaurantData.value.url_pyszne,
          "availability": "https://schema.org/InStock",
          "priceCurrency": "PLN"
        } : undefined,
        "aggregateRating": restaurantData.value.ratingValue && restaurantData.value.reviewCount ? {
          "@type": "AggregateRating",
          "ratingValue": restaurantData.value.ratingValue.toString(),
          "reviewCount": restaurantData.value.reviewCount.toString()
        } : undefined,
        "award": restaurantData.value.isMuala ? "MUALA Badge" : undefined
      };
    }
  }

  return { title, description, image, url, schema };
});

// Dynamiczna aktualizacja JSON-LD
watch(metaData, () => {
  nextTick(() => {
    document.querySelectorAll('script[type="application/ld+json"]').forEach(el => el.remove());

    if (metaData.value.schema) {
      useHead({
        script: [
          {
            type: "application/ld+json",
            innerHTML: JSON.stringify(metaData.value.schema)
          }
        ]
      });
    }
  });
}, { deep: true });

</script>







<style>
body {
  margin: 0px;
  padding: 0px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000000;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
