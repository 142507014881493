<!-- components/SchemaOrg.vue -->
<template>
    <div style="display: none;"></div>
  </template>
  
  <script setup>
  import { computed, onMounted, onUnmounted, watch } from 'vue';
 
  
  const props = defineProps({
    type: {
      type: String,
      required: true
    },
    data: {
      type: Object,
      required: true
    }
  });
  
  const schema = computed(() => {
    switch (props.type) {
      case 'homepage':
        return {
          "@context": "https://schema.org",
          "@type": "WebSite",
          "name": "Muala Food App",
          "url": "https://muala.app",
          "potentialAction": {
            "@type": "SearchAction",
            "target": "https://muala.app/lista/{search_term_string}",
            "query-input": "required name=search_term_string"
          }
        };
  
      case 'cityList':
        return {
          "@context": "https://schema.org",
          "@type": "ItemList",
          "itemListElement": props.data.cities.map((city, index) => ({
            "@type": "ListItem",
            "position": index + 1,
            "item": {
              "@type": "City",
              "name": city,
              "url": `https://muala.app/lista/${city.toLowerCase()}`
            }
          }))
        };
  
      case 'restaurantList':
        return {
          "@context": "https://schema.org",
          "@type": "ItemList",
          "itemListElement": props.data.restaurants.map((restaurant, index) => ({
            "@type": "ListItem",
            "position": index + 1,
            "item": {
              "@type": "Restaurant",
              "name": restaurant.title,
              "url": `https://muala.app/${restaurant.city.toLowerCase()}/${restaurant.title.toLowerCase().replace(' - ', ' ').replace(/ /g, '-')}.html`
            }
          }))
        };
  
        case 'restaurant':
  return {
    "@context": "https://schema.org",
    "@type": "Restaurant",
    "name": props.data.title,
    "url": props.data.url,
    "address": {
      "@type": "PostalAddress",
      "addressCountry": "Poland",
      "addressLocality": props.data.address.city,
      "streetAddress": props.data.address.street,
      "postalCode": props.data.address.postalCode
    },
    ...(props.data.coordinates && {
      "geo": {
        "@type": "GeoCoordinates",
        "latitude": props.data.coordinates.lat,
        "longitude": props.data.coordinates.lng
      }
    }),
    ...(props.data.rating && {
      "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": props.data.rating.value,
        "reviewCount": props.data.rating.count
      }
    }),
    "servesCuisine": props.data.type,
    ...(props.data.openingHours && {
      "openingHours": props.data.openingHours
    }),
    ...(props.data.images && {
      "image": props.data.images
    }),
    ...(props.data.cid && {
      "hasMap": `https://maps.google.com/?cid=${props.data.cid}`
    })
  };
  
        case 'aboutPage':
            return {
                "@context": "https://schema.org",
                "@type": "AboutPage",
                "name": props.data.title,
                "url": props.data.url,
                "description": props.data.description,
                // "datePublished": props.data.datePublished,
                // "dateModified": props.data.dateModified,
                "publisher": {
                    "@type": "Organization",
                    "name": "Muala App Food",
                    "url": "https://muala.app"
                }
            };
    }
  });
  let scriptElement = null;

const updateSchema = () => {
  if (scriptElement) {
    scriptElement.remove();
  }
  
  if (schema.value) {
    scriptElement = document.createElement('script');
    scriptElement.setAttribute('type', 'application/ld+json');
    scriptElement.textContent = JSON.stringify(schema.value);
    document.head.appendChild(scriptElement);
  }
};

// Aktualizuj schema gdy zmienią się props
watch(() => props.data, updateSchema, { deep: true });
watch(() => props.type, updateSchema);

onMounted(() => {
  updateSchema();
});

onUnmounted(() => {
  if (scriptElement) {
    scriptElement.remove();
  }
});
</script>