import { createRouter, createWebHistory } from "vue-router";
import MapView from "../views/MapView.vue";
import { locationRoutes } from "../data/locationRoutes";
import NotFoundComponent from "../views/NotFound.vue";
import Opis from "../views/Opis.vue";
import Wsparcie from "../views/Wsparcie.vue";
import ListaCity from "../views/ListCity.vue";
import ListRestaurants from "../views/ListRestaurants.vue";




const staticRoutes = [
  {
    path: "/",
    name: "map",
    component: MapView,
    meta: {
      title: "MUALA - Mapa miejsc odwiedzonych przez Ksiazula i Wojka..",
    },
  },
  {
    path: "/o-pomysle",
    name: "opis",
    component: Opis,
    meta: {
      title: "MUALA - Opis Projektu",
    },
  },
  {
    path: "/lista",
    name: "lista",
    component: ListaCity,
    meta: {
      title: "Lista miejsc, tych odwiedzonych i tych oznaczonych 'MUALA'",
    },
  },
  {
    path: '/lista/:city',
    name: 'cityList',
    component: ListRestaurants,
  },
  
  {
    path: "/wsparcie",
    name: "wsparcie",
    component: Wsparcie,
    meta: {
      title: "Wsparcie Projektu Muala Mapa",
    },
  },
  {
    path: "/:city/:restaurantName.html",
    component: MapView,
  },
];


const cityRoutes = locationRoutes.map((location) => ({
  path: location.path,
  component: MapView,
  props: {
    // default: true,
    centerNew: location.position,
    zoomNew: location.zoom,
    nameNew: location.name,
  },
  meta: {
    title: `MUALA - ${location.name} - Mapa Opinie Video Ksiazulo i Wojek...`,
    description: `Poznaj ${location.name}, odkrywaj ciekawe miejsca i doświadczaj niezapomnianych przygód z MUALA.`,
  },
}));

const routes = [
  ...staticRoutes,
  ...cityRoutes,
  {
    path: "/:singleSegment",
    component: MapView,
    beforeEnter: (to, from, next) => {
      const pathMatch = to.params.singleSegment;
      if (!pathMatch.includes(".")) {
        next();
      } else {
        next({ name: "NotFound" });
      }
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: NotFoundComponent, 
    meta: {
      title: "404 Not Found - MUALA",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

function updateMetaDescription(content) {
  let meta = document.querySelector('meta[name="description"]');
  if (meta) {
    meta.setAttribute("content", content);
  } else {
    meta = document.createElement("meta");
    meta.setAttribute("name", "description");
    meta.setAttribute("content", content);
    document.getElementsByTagName("head")[0].appendChild(meta);
  }
}

function toTitleCase(str) {
  const withSpaces = str.replace(/-/g, " ");
  const titleCased = withSpaces
    .split(" ")
    .map((word, index) => {
      if (word.length > 2 || index === 0) {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      } else {
        return word.toLowerCase();
      }
    })
    .join(" ");

  return titleCased;
}

router.beforeEach((to, from, next) => {
  try {
      // Ustawienie dynamicznych tytułów i opisów dla tras z parametrami
      if (to.params.city && to.params.restaurantName) {
        const city = decodeURIComponent(to.params.city);
        const restaurantName = decodeURIComponent(
          to.params.restaurantName.replace(".html", "")
        );
        document.title = `${toTitleCase(restaurantName)} opinia - ${toTitleCase(
          city
        )} odkryj z Książulem i Wojkiem | MUALA`;

        const description = `Podążaj za Książulem i Wojkiem do ${toTitleCase(
          restaurantName
        )} ${toTitleCase(
          city
        )}. Odkryj, jakie tajemnice i historie kryją się w odwiedzanych miejscach.`;
        updateMetaDescription(description);
      } else {
        // Ustawienie tytułu i opisu z meta danych trasy, jeśli dostępne
        document.title = to.meta.title || "Mapa MUALA - Twoja Mapa Odkryć Kulinarnych";
        const description =
          to.meta.description ||
          "Odkrywaj świat z MUALA razem z Książulem i Wojkiem. Sprawdź opinie i znajdź miejsca, które pokochasz.";
        updateMetaDescription(description);
      }

      next();
    } catch (error) {
      console.error(`Routing error before entering ${to.path}:`, error);
      // Możesz przekierować na \ błędu lub po prostu wywołać next() z błędem
      next(false); // Zatrzymaj nawigację
      // next({ name: 'Error' }); 
    }    
});






router.afterEach(() => {
  try {
    window.scrollTo(0, 0);
  } catch (error) {
    console.error(`Error after leaving ${from.path} and entering ${to.path}:`, error);
  }
});

export default router;
